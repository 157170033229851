@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Define Breakpoints
$breakpoints: (
  "small": 480px,
  "medium": 768px,
  "large": 1024px,
  "xlarge": 1200px,
);

$brand: #078407;

@mixin media($size) {
  @if map-has-key($breakpoints, $size) {
    @media (min-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @warn "The breakpoint `#{$size}` is not defined.";
  }
}

html {
  scroll-behavior: smooth;
}
.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Roboto Slab";
  }

  p {
    font-size: 18px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    font-family: "Lato", sans-serif;
  }
  a {
    font-family: "Lato", sans-serif;
  }

  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }

  .light-text {
    font-weight: 300;
  }

  button {
    height: 52px;
    padding: 0 16px;
    border-radius: 5px;
    outline: none;
    background-color: $brand;
    border: 2px solid $brand;
    color: white;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    user-select: none;
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      @extend .button-outline;
      box-shadow: 1px 3px 8px 2px rgba(0, 0, 0, 0.1);
    }
  }

  .button-outline {
    background-color: white;
    color: $brand;
  }

  .app-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: black;

    img {
      margin: 0 16px;
      max-height: 112px;
    }

    .links {
      p {
        font-weight: 800;
        color: #078407;
        padding: 0 16px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  #about {
    margin-top: 32px;

    @include media("medium") {
    }
  }

  .resume-block {
    display: flex;
    justify-content: center;
    padding: 24px;
    button {
      width: 100%;
      @include media("medium") {
        width: 40%;
      }
    }
  }

  #contact {
    margin-top: 32px;

    .contact-block {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 24px;
      gap: 16px;
      button {
        flex: 100%;
      }
      @include media("medium") {
        button {
          flex: 45%;
        }
      }
      @include media("large") {
        button {
          flex: 20%;
        }
      }
    }
  }

  .section {
    padding: 24px 0px;
    max-width: 1200px;

    .section-heading {
      text-align: center;
    }
    .section-content {
      padding: 24px;
      margin-bottom: 16px;
    }

    @include media("medium") {
      padding: 24px 16px;
      padding: 24px;
      margin: 24px auto;

      .section-heading {
        text-align: left;
        padding: 24px 24px 0;
      }
    }
  }
}

.work-item {
  box-shadow: 1px 3px 8px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  border-radius: 20px;
}
.work-link {
  text-decoration: none;
  .work-link-i {
    color: #078407;
    font-size: 16px;
  }
}
.work-title {
  font-size: 18px;
}
.used-tech {
  margin: 16px 0;
  color: black;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  svg {
    font-size: 24px;
  }
}

.work-project-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .project-name {
    flex: 1;
  }

  .project-detail {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin: 1rem 0;

    .project-features,
    .project-image {
      width: 100%;
      @include media("medium") {
        flex: 1;
      }
    }

    .project-features {
      order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        list-style-position: inside;
        .feature {
          font-size: 1.25rem;
        }
      }
    }
    .project-image {
      order: 0;
    }
  }

  .project-image {
    img {
      width: 100%;
      border-radius: 0.5rem;
      box-shadow: 1px 3px 8px 2px rgba(0, 0, 0, 0.1);
    }
  }
}
